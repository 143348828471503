define('cifunhi/routes/administrador/expedientes/nuevo/datos-generales', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		model() {
			return this.store.createRecord('kid', {
				datosGenerales: this.store.createRecord('datos-generales'),
				caracteristicasFisicas: this.store.createRecord('caracteristicas-fisicas'),
				servicioMedico: this.store.createRecord('servicio-medico')
			});
		}
	});
});