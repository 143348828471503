define('cifunhi/controllers/administrador/expedientes/nuevo/anexos', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		actions: {
			guardar() {
				this.get('model').save().then(() => {
					this.transitionToRoute('administrador.expedientes');
				});
			}
		}
	});
});