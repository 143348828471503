define('cifunhi/models/questionary-kid', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		preguntas: _emberData.default.hasMany('question-kid'),
		cuestionario: _emberData.default.belongsTo('cuestionario'),
		password: _emberData.default.attr('string'),
		kid: _emberData.default.belongsTo('kid'),
		fechaRespondido: _emberData.default.attr('number'),
		fechaAplicado: _emberData.default.attr('number')
	});
});