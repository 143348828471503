define('cifunhi/controllers/administrador/expedientes/nuevo/datos-generales', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    actions: {
      saved(model) {
        model.save().then(nino => {
          nino.get('datosGenerales').then(datosGenerales => {
            datosGenerales.save().then(() => {
              model.get('caracteristicasFisicas').then(caracteristicasFisicas => {
                caracteristicasFisicas.save().then(() => {
                  model.get('servicioMedico').then(servicioMedico => {
                    servicioMedico.save().then(() => {
                      this.transitionToRoute('administrador.expedientes.nuevo.documentos', this.get('model'));
                    });
                  });
                });
              });
            });
          });
        });
      },
      cancelar(model) {
        model.destroyRecord();
        this.transitionToRoute('administrador');
      }
    }
  });
});