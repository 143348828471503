define('cifunhi/components/kid-general-data', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    manuallyLN: true,
    manuallyDIR: true,
    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    salas: ['preescolar', 'primaria', 'secundaria', 'otros'],
    didInsertElement() {
      this.initializeDatepicker();
    },
    initializeDatepicker(date) {
      if (!date) {
        date = new Date(2000, 0, 0);
      }
      window.$('.datepicker').datepicker({
        defaultDate: date,
        format: 'dd/mm/yyyy',
        setDefaultDate: true,
        yearRange: 40,
        i18n: {
          months: ['Enero', 'Febrero', 'Marzo', 'Abrio', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
          monthsShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
          weekdays: ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'],
          weekdaysShort: ['Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab', 'Dom'],
          weekdaysAbbrev: ['L', 'M', 'M', 'J', 'V', 'S', 'D'],
          cancel: 'Cancelar',
          clear: 'Limpiar',
          done: 'Listo'
        }

      });
    },
    actions: {
      manually(type) {
        if (type == 0) {
          this.set('manuallyLN', true);
        } else {
          this.set('manuallyDIR', true);
        }
      },
      setFecha(event) {
        if (event.target.value) {
          let model = this.get('model');
          model.set('datosGenerales.fechaNacimiento', (0, _moment.default)(event.target.value, "DD/MM/YYYY").unix());
        }
      },
      siguiente() {

        this.isSaved();
      },
      cancelar() {
        this.onCancelar();
      },
      searchLN(code, direccion) {
        this.set('manuallyLN', false);
        return this.get('ajax').request(`/codigo_postal/${code}`, {
          method: 'GET'
        }).then(data => {
          direccion.set('lnMunicipio', data.municipio);
          direccion.set('lnEntidad', data.estado);
          this.set('coloniasLN', data.colonias);
          this.set('searchedLN', true);
        });
      },
      searchDIR(code, direccion) {
        this.set('manuallyDIR', false);
        return this.get('ajax').request(`/codigo_postal/${code}`, {
          method: 'GET'
        }).then(data => {
          direccion.set('dMunicipio', data.municipio);
          this.set('coloniasDIR', data.colonias);
          this.set('searchedDIR', true);
        });
      },

      editar(valor) {
        this.set(valor, true);
        this.get('model.datosGenerales').then(dg => {
          setTimeout(() => {
            const date = new Date(dg.get('fechaNacimiento') * 1000);
            this.initializeDatepicker(date);
          }, 300);
        });
      },
      cancelarEdit(modelo, valor) {
        modelo.get('datosGenerales').then(datos => {
          if (!Ember.isEmpty(datos) && datos.get('hasDirtyAttributes')) {
            datos.rollbackAttributes();
          }
        });
        modelo.get('caracteristicasFisicas').then(caracteristicasFisicas => {
          if (!Ember.isEmpty(caracteristicasFisicas) && caracteristicasFisicas.get('hasDirtyAttributes')) {
            caracteristicasFisicas.rollbackAttributes();
          }
        });
        this.set(valor, false);
      },
      guardarEdit(modelo, valor) {

        const promises = [];
        modelo.get('datosGenerales').then(datos => {
          if (!Ember.isEmpty(datos) && datos.get('hasDirtyAttributes')) {
            promises.push(datos.save());
          }
        });
        modelo.get('caracteristicasFisicas').then(caracteristicasFisicas => {
          if (!Ember.isEmpty(caracteristicasFisicas) && caracteristicasFisicas.get('hasDirtyAttributes')) {
            promises.push(caracteristicasFisicas.save());
          }
        });
        Ember.RSVP.all(promises).then(() => {
          modelo.save().then(() => {
            this.set(valor, false);
          });
        });
      }
    }
  });
});