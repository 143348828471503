define('cifunhi/controllers/cuestionario', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    clonarCuestionario() {
      let model = this.get('model');
      let cntx = this;

      model.set('fechaRespondido', (0, _moment.default)().unix());
      model.get('cuestionario').then(cuestionario => {
        cuestionario.get('preguntas').then(preguntas => {
          preguntas.forEach(function (pregunta) {
            cntx.store.createRecord('question-kid', {
              tipo: pregunta.tipo,
              pregunta: pregunta.pregunta,
              guardado: pregunta.guardado,
              cuestionario: model
            }).save().then(preguntaKid => {
              model.get('preguntas').then(preguntasKid => {
                preguntasKid.pushObject(preguntaKid);
                model.save();
              });
              pregunta.get('respuestas').then(respuestas => {
                respuestas.forEach(function (respuesta) {
                  cntx.store.createRecord('answer-kid', {
                    pregunta: preguntaKid,
                    respuesta: respuesta.respuesta,
                    guardado: respuesta.guardado,
                    checked: respuesta.checked
                  }).save().then(respuestaNewKid => {
                    preguntaKid.get('respuestas').then(respuestasNewKid => {
                      respuestasNewKid.pushObject(respuestaNewKid);
                      preguntaKid.save();
                    });
                  });
                });
              });
            });
          });
        });
      });

      model.save();
    },

    actions: {
      login(pswd) {
        let model = this.get('model');

        if (model.password == pswd) {

          this.set('correctPassword', true);
        } else {
          alert('Contaseña incorrecta.');
        }
      },
      sendQuestionary() {
        this.clonarCuestionario();
        //this.transitionToRoute('/')
      },
      deb(pregunta, respuesta) {
        pregunta.get('respuestas').forEach(res => {
          res.set('checked', false);
        });
        respuesta.set('checked', true);
      }
    }
  });
});