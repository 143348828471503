define('cifunhi/models/question-kid', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		tipo: _emberData.default.attr('string'),
		pregunta: _emberData.default.attr('string'),
		guardado: _emberData.default.attr('boolean'),
		cuestionario: _emberData.default.belongsTo('questionary-kid'),
		respuestas: _emberData.default.hasMany('answer-kid')
	});
});