define('cifunhi/routes/administrador/expedientes/index', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		queryParams: {
			sala: {
				refreshModel: true
			}
		},
		model(params) {
			if (params.sala) {
				return this.store.query('kid', {
					orderBy: 'sala',
					equalTo: params.sala

				});
			} else {
				return this.store.findAll('kid');
			}
		}
	});
});