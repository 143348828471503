define('cifunhi/controllers/administrador/cuestionarios/nuevo', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        actions: {
            nuevaPregunta(tipo) {
                let model = this.get('model');
                console.log(model);
                let pregunta;
                if (tipo == 'abierta') {
                    pregunta = this.store.createRecord('question', {
                        cuestionario: model,
                        tipo: tipo
                    });
                    let respuesta = this.store.createRecord('answer', {
                        pregunta: pregunta
                    });
                    pregunta.get('respuestas').then(reList => {
                        reList.pushObject(respuesta);
                    });
                } else {
                    pregunta = this.store.createRecord('question', {
                        cuestionario: model,
                        tipo: tipo
                    });
                }
                console.log(pregunta);
                model.get('preguntas').then(preList => {
                    preList.pushObject(pregunta);
                });
                console.log(model);
            },
            guardarCuestionario() {
                let model = this.get('model');
                model.get('preguntas').then(preguntas => {
                    preguntas.forEach(pregunta => {
                        pregunta.get('respuestas').then(respuestas => {
                            respuestas.forEach(respuesta => {

                                respuesta.save();
                            });
                        });

                        pregunta.save();
                    });
                    model.save();
                    swal('Guardado!', 'La información ha sido almacenada', 'success');
                    this.transitionToRoute('administrador.cuestionarios.index');
                });
            }
        }
    });
});