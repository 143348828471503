define('cifunhi/routes/administrador/cuestionarios/aplicar', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		model(params) {
			return this.store.findRecord('cuestionario', params.cuestionario_id);
		},
		setupController() {
			this._super(...arguments);

			this.set('questionaryUrl', undefined);
			this.set('selectedKid', undefined);
		}
	});
});