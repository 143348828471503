define('cifunhi/routes/administrador', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        session: Ember.inject.service(),
        beforeModel(transition) {
            return this.get('session').fetch().then(() => {
                if (!this.get('session.isAuthenticated')) {
                    transition.abort();
                    return this.transitionTo('login');
                }
            }).catch(() => {
                if (!this.get('session.isAuthenticated')) {
                    transition.abort();
                    return this.transitionTo('login');
                }
            });
        }
    });
});