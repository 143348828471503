define('cifunhi/models/kid', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    datosGenerales: _emberData.default.belongsTo('datos-generales'),
    caracteristicasFisicas: _emberData.default.belongsTo('caracteristicas-fisicas'),
    servicioMedico: _emberData.default.belongsTo('servicio-medico'),
    documentos: _emberData.default.hasMany('documento'),
    cuestionarios: _emberData.default.hasMany('questionary-kid'),
    anexos: _emberData.default.hasMany('anexo'),
    sala: _emberData.default.attr('string'), // para ordenar

    lastName: Ember.computed('datosGenerales.apellidoPaterno', 'datosGenerales.apellidoMaterno', function () {
      return this.datosGenerales.get('apellidoPaterno') + " " + this.datosGenerales.get('apellidoMaterno');
    }),

    fullName: Ember.computed('datosGenerales.nombre', 'lastName', function () {
      return this.datosGenerales.get('nombre') + " " + this.lastName;
    })

  });
});