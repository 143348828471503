define('cifunhi/controllers/administrador/fotos', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    firebaseApp: Ember.inject.service(),
    actions: {
      cancelar() {
        this.set('photoDescription', undefined);
        this.set('photoTitle', undefined);
        this.set('newPhoto', false);
      },
      agregar() {
        this.set('newPhoto', true);
      },
      guardar() {
        let pdf = window.$('#photoUpload')[0].files[0];

        let model = this.store.createRecord('photo', {
          title: this.get('photoTitle'),
          description: this.get('photoDescription')
        });
        let cntx = this;
        let storageRef = cntx.get('firebaseApp').storage().ref();

        if (pdf.type.match('image/*')) {
          let fileType = pdf.type.split("/")[1];
          var reader = new FileReader();
          reader.readAsArrayBuffer(pdf);
          reader.onload = function (e) {
            var data = e.target.result;
            var imgRef = storageRef.child(`fotos/${model.id}.${fileType}`);
            imgRef.put(data).then(function (snapshot) {

              model.set('url', snapshot.downloadURL);
              model.set('fileName', `${model.id}.${fileType}`);

              return model.save().then(() => {
                cntx.set('photoDescription', undefined);
                cntx.set('photoTitle', undefined);
                cntx.set('newPhoto', false);
              });
            });
          };
        }
      },

      borrar(photo) {
        let cntx = this;
        let storageRef = cntx.get('firebaseApp').storage().ref();
        let photoRef = storageRef.child(`fotos/${photo.fileName}`);

        window.swal({
          title: 'Eliminar',
          text: "¿Seguro de que desea borrar esta foto?",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          cancelButtonText: 'No',
          confirmButtonText: 'Si'
        }).then(result => {
          if (result.value) {
            return photoRef.delete().then(() => {
              return photo.destroyRecord().then(() => {
                Swal('Eliminado', 'La imagen se ha eliminado correctamente', 'success');
              });
            });
          }
        });
      }
    }
  });
});