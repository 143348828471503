define('cifunhi/controllers/administrador/expedientes/nuevo/documentos', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		actions: {
			guardado(model) {
				model.save().then(() => {
					this.transitionToRoute('administrador.expedientes.nuevo.anexos', model);
				});
			}
		}
	});
});