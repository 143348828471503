define("cifunhi/controllers/administrador/expedientes/index", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
        columns: [{
            "template": "id",
            "title": "id"
        }],

        actions: {
            verExpediente(kid) {
                this.transitionToRoute('administrador.expedientes.ver', kid);
            },
            eliminar(kid) {
                swal({
                    title: '¿Estás seguro de eliminar el expediente?',
                    text: "Esta accion no se podrá revertir",
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Si, eliminar'
                }).then(result => {
                    if (result.value) {
                        kid.destroyRecord();
                    }
                });
            }
        }
    });
});