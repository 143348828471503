define('cifunhi/controllers/administrador', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		actions: {
			signOut: function () {

				swal({
					title: '¿Esta seguro de cerrar sesion?',
					type: 'Cuidado',
					showCancelButton: true,
					cancelButtonText: 'No',
					confirmButtonColor: '#d33',
					cancelButtonColor: '#3085d6',
					confirmButtonText: '¡Si, cierrala!'
				}).then(result => {
					if (result.value) {
						this.get('session').close();
						this.transitionToRoute('login');
					}
				});
			}
		}
	});
});