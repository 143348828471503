define('cifunhi/controllers/success', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		actions: {
			goToIndex() {
				this.transitionToRoute('index');
			}
		}
	});
});