define('cifunhi/controllers/administrador/expedientes/ver/index', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		verDatosGenerales: true,
		verDocumentos: true,
		verAnexos: true,

		actions: {
			verCuestionario(cuestionario) {
				this.transitionToRoute('administrador.expedientes.ver.cuestionarios', cuestionario);
			},
			editar(tipo) {
				this.set(tipo, false);
			},
			guardar(model, tipo) {
				model.save().then(() => {
					this.set(tipo, true);
				});
			},
			cancelar(model, tipo) {
				model.rollbackAttributes();

				this.set(tipo, true);
			}

		}
	});
});