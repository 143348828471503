define('cifunhi/controllers/administrador/cuestionarios/editar', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        actions: {
            nuevaPregunta(tipo) {
                let model = this.get('model');
                console.log(model);
                let pregunta = this.store.createRecord('question', {
                    cuestionario: model,
                    tipo: tipo,
                    guardado: false
                });
                console.log(pregunta);
                model.get('preguntas').then(preList => {
                    preList.pushObject(pregunta);
                });
                console.log(model);
            },
            guardarCuestionario() {
                let model = this.get('model');
                model.get('preguntas').then(preguntas => {
                    preguntas.forEach(pregunta => {
                        pregunta.get('respuestas').then(respuestas => {
                            respuestas.forEach(respuesta => {
                                respuesta.set('guardado', true);
                                respuesta.save();
                            });
                        });
                        pregunta.set('guardado', true);
                        pregunta.save();
                    });
                    model.save();
                    swal('Guardado!', 'La información ha sido almacenada', 'success');
                    this.transitionToRoute('administrador.cuestionarios.index');
                });
            }
        }
    });
});