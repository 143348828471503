define('cifunhi/routes/administrador/expedientes/ver/cuestionarios', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		model(params) {
			return this.store.findRecord('questionary-kid', params.cuestionario_id);
		}
	});
});