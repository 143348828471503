define('cifunhi/routes/cuestionario', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({

		model(params) {
			return this.store.findRecord('questionary-kid', params.cuestionario_id);
		}
	} // removed duplicated question
	/* afterModel(model){
 	model.get('cuestionario').then((cuestionario)=>{
 		cuestionario.get('preguntas').then((preguntas)=>{
 			preguntas.forEach((pregunta)=>{
 				if(pregunta.get('tipo')=="abierta") {
 					pregunta.get('respuestas').createRecord();
 				}
 			})
 		})
 	})
 	} */
	);
});