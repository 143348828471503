define('cifunhi/router', ['exports', 'cifunhi/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('login');
    this.route('cuestionario', { path: '/cuestionario/:cuestionario_id' });
    this.route('administrador', function () {
      this.route('expedientes', function () {
        this.route('ver', { path: '/ver/:kid_id' }, function () {
          this.route('cuestionarios', { path: 'cuestionario/:cuestionario_id' });
        });
        this.route('editar', function () {
          this.route('datosGenerales');
          this.route('documentos');
          this.route('anexos');
        });
        this.route('nuevo', function () {
          this.route('datosGenerales');
          this.route('documentos', { path: '/documentos/:kid_id' });
          this.route('anexos', { path: '/anexos/:kid_id' });
        });
      });
      this.route('cuestionarios', function () {
        this.route('nuevo');
        this.route('editar', { path: '/editar/:cuestionario_id' });
        this.route('aplicar', { path: '/aplicar/:cuestionario_id' });
        this.route('detalles', { path: '/detalles/:cuestionario_id' });
      });
      this.route('fotos');
    });
    this.route('success');
    this.route('cancel');
  });

  exports.default = Router;
});