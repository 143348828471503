define('cifunhi/components/paypal-button', ['exports', 'cifunhi/config/environment', 'ember-window-mock'], function (exports, _environment, _emberWindowMock) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const {
    env
  } = _environment.default['paypal-button'];

  exports.default = Ember.Component.extend({
    didReceiveAttrs() {
      this._super(...arguments);

      let actions = this.get('paypalActions');
      if (!actions) {
        return;
      }

      this.updateButtonState(actions);
    },

    didInsertElement() {
      this._super(...arguments);

      Ember.run.scheduleOnce('afterRender', () => {
        try {
          this.renderPaypal();
        } catch (error) {
          // Paypal script did not load or errored on render
        }
      });
    },

    renderPaypal() {
      let { paypal } = _emberWindowMock.default;

      // This leaks: https://github.com/krakenjs/xcomponent/issues/116
      paypal.Button.render({
        env,
        commit: true, // Show a 'Pay Now' button
        style: {
          shape: 'rect',
          size: 'responsive'
        },

        validate: actions => {
          Ember.run(() => {
            this.set('paypalActions', actions);
            this.updateButtonState(actions);
          });
        },

        payment: () => {
          return Ember.run(() => {
            return this.get('payment')();
          });
        },

        onAuthorize: (data, actions) => {
          return Ember.run(() => {
            return this.get('onAuthorize')(data, actions);
          });
        },

        onCancel: (data, actions) => {
          return Ember.run(() => {
            return this.get('onCancel')(data, actions);
          });
        },

        onError: error => {
          return Ember.run(() => {
            return this.get('onError')(error);
          });
        }
      }, this.elementId);
    },

    updateButtonState(actions) {
      if (this.get('isEnabled')) {
        actions.enable();
      } else {
        actions.disable();
      }
    }
  });
});