define('cifunhi/components/kid-documents', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    acta: null,
    curp: null,
    ine: null,
    comprobante: null,
    historial: null,

    store: Ember.inject.service(),
    firebaseApp: Ember.inject.service(),

    documentoRef: null,
    saveFile(documento, nombreDocumento, documentoRef) {

      //this.set('documentoRef', documento)
      //let nombreDocumentos = ['acta', 'curp', 'ine', 'comprobante', 'historial'];

      //let pdf = event.target.files[0]
      let pdf = window.$(documentoRef)[0].files[0];
      $;
      let model = this.get('model');
      let cntx = this;
      let storageRef = cntx.get('firebaseApp').storage().ref();

      documento.set('nombre', nombreDocumento);

      if (pdf.type.match('.pdf') || pdf.type.match('image/*')) {
        let fileType = pdf.type.split("/")[1];
        var reader = new FileReader();
        reader.readAsArrayBuffer(pdf);
        reader.onload = function (e) {
          var data = e.target.result;
          var imgRef = storageRef.child(`documentos/${model.get('id')}/${nombreDocumento}.${fileType}`);
          imgRef.put(data).then(function (snapshot) {

            documento.set('archivo', snapshot.downloadURL);

            return documento.save();
          });
        };
      }
    },
    actions: {
      editar(valor) {
        this.set(valor, true);
      },
      cancelarEdit(valor) {
        this.set(valor, false);
      },
      saveActaRef() {
        this.set('acta', event.target.files[0]);
      },
      saveCurpRef() {
        this.set('curp', event.target.files[1]);
      },
      saveIneRef() {
        this.set('ine', event.target.files[2]);
      },
      saveComprobanteRef() {
        this.set('comprobante', event.target.files[3]);
      },
      saveHistorialRef() {
        this.set('historial', event.target.files[4]);
      },
      saveDocuments(model) {
        model.get('documentos').then(documentos => {

          //Guardando acta de nacimiento
          this.get('store').createRecord('documento', {
            nino: model
          }).save().then(actaGuardada => {
            this.saveFile(actaGuardada, 'acta', "#acta");
            actaGuardada.save().then(() => {
              documentos.pushObject(actaGuardada);
            });
          });
          //Guardando CURP
          this.get('store').createRecord('documento', {
            nino: model
          }).save().then(curpGuardado => {
            this.saveFile(curpGuardado, 'curp', "#curp");
            curpGuardado.save().then(() => {
              documentos.pushObject(curpGuardado);
            });
          });
          //Guardando INE
          this.get('store').createRecord('documento', {
            nino: model
          }).save().then(ineGuardado => {
            this.saveFile(ineGuardado, 'ine', "#ine");
            ineGuardado.save().then(() => {
              documentos.pushObject(ineGuardado);
            });
          });
          //Guardando Comprobante de domicilio
          this.get('store').createRecord('documento', {
            nino: model
          }).save().then(comprobanteGuardado => {
            this.saveFile(comprobanteGuardado, 'comprobante', "#comprobante");
            comprobanteGuardado.save().then(() => {
              documentos.pushObject(comprobanteGuardado);
            });
          });
          //Guardando historial
          this.get('store').createRecord('documento', {
            nino: model
          }).save().then(historialGuardado => {
            this.saveFile(historialGuardado, 'historial_medico', "#historial");
            historialGuardado.save().then(() => {
              documentos.pushObject(historialGuardado);
            });
          });

          model.save().then(() => {
            this.onGuardado();
          });
        });
      }
    }
  });
});