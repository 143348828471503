define('cifunhi/components/materialize-checkbox', ['exports', 'cifunhi/components/md-check'], function (exports, _mdCheck) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _mdCheck.default.extend({
    init() {
      this._super(...arguments);
      Ember.deprecate('{{materialize-checkbox}} has been deprecated. Please use {{md-check}} instead', false, {
        url: 'https://github.com/sgasser/ember-cli-materialize/issues/67'
      });
    }
  });
});