define('cifunhi/controllers/administrador/cuestionarios/aplicar', ['exports', 'moment', 'ember-data'], function (exports, _moment, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({

		selectedKid: null,
		questionaryUrl: null,
		kids: Ember.computed(function () {
			return _emberData.default.PromiseObject.create({
				promise: new Promise(res => {
					return this.store.findAll('kid').then(kids => {
						return res(kids);
					});
				})
			});
		}),

		init() {
			this._super(...arguments);
			this.set('pswd', "123123");
		},

		actions: {

			onSelectedKid(kid) {
				this.set('selectedKid', kid);
			},

			aplicar(cuestionario) {
				console.log(cuestionario);
				//let cuestionario = this.get('model.cuestionario');

				this.store.createRecord('questionary-kid', {
					cuestionario: cuestionario,
					kid: this.get('selectedKid'),
					password: this.get('pswd'),
					fechaAplicado: (0, _moment.default)().unix()
				}).save().then(questionaryKid => {
					let selectedKid = this.get('selectedKid');

					selectedKid.get('cuestionarios').then(cuestionarios => {
						cuestionarios.pushObject(questionaryKid);
						selectedKid.save();
					});

					cuestionario.get('questionaryKids').then(cuestionariosKid => {
						cuestionariosKid.pushObject(questionaryKid);
						cuestionario.save().then(() => {
							this.set('questionaryUrl', "ciegosfundacionhgo.org/cuestionario/" + questionaryKid.get('id'));
						});
					});
				});
			}
		}
	});
});