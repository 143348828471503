define('cifunhi/models/documento', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		nombre: _emberData.default.attr('string'),
		archivo: _emberData.default.attr('string'),
		nino: _emberData.default.belongsTo('kid')

	});
});