define('cifunhi/models/datos-generales', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		nombre: _emberData.default.attr('string'),
		apellidoPaterno: _emberData.default.attr('string'),
		apellidoMaterno: _emberData.default.attr('string'),
		foto: _emberData.default.attr('string'),
		fechaNacimiento: _emberData.default.attr('number'),
		lnLocalidad: _emberData.default.attr('string'),
		lnMunicipio: _emberData.default.attr('string'),
		lnEntidad: _emberData.default.attr('string'),
		lnCP: _emberData.default.attr('string'),
		edad: _emberData.default.attr('number'), //Este se calcula no???
		escolaridad: _emberData.default.attr('string'),
		telefono: _emberData.default.attr('string'),
		dCP: _emberData.default.attr('string'),
		dCalle: _emberData.default.attr('string'),
		dNumero: _emberData.default.attr('string'),
		dColonia: _emberData.default.attr('string'),
		dMunicipio: _emberData.default.attr('string'),
		hermanos: _emberData.default.attr('boolean'),
		cantidadHermanos: _emberData.default.attr('number'),
		nombreMama: _emberData.default.attr('string'),
		telefonoMama: _emberData.default.attr('string'),
		nombrePapa: _emberData.default.attr('string'),
		telefonoPapa: _emberData.default.attr('string'),
		nino: _emberData.default.belongsTo('kid')
	});
});