define('cifunhi/models/cuestionario', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		nombre: _emberData.default.attr('string'),
		descripcion: _emberData.default.attr('string'),
		preguntas: _emberData.default.hasMany('question'),
		questionaryKids: _emberData.default.hasMany('questionary-kid')
	});
});