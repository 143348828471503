define('cifunhi/components/pregunta-abierta', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        store: Ember.inject.service(),
        actions: {
            eliminarPregunta(pregunta) {
                swal({
                    title: '¿Estás seguro de eliminar la pregunta?',
                    text: "Esta accion no se podrá revertir",
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Si, eliminar'
                }).then(result => {
                    if (result.value) {
                        pregunta.destroyRecord();
                    }
                });
            }
        }
    });
});