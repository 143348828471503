define('cifunhi/components/materialize-button-submit', ['exports', 'cifunhi/components/md-btn-submit'], function (exports, _mdBtnSubmit) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _mdBtnSubmit.default.extend({
    init() {
      this._super(...arguments);
      Ember.deprecate('{{materialize-button-submit}} has been deprecated. Please use {{md-btn-submit}} instead', false, {
        url: 'https://github.com/sgasser/ember-cli-materialize/issues/67'
      });
    }
  });
});