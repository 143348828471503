define('cifunhi/controllers/administrador/cuestionarios/index', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        actions: {
            eliminarCuestionario(cuestionario) {
                swal({
                    title: '¿Estás seguro de eliminar el cuestionario?',
                    text: "Esta accion no se podrá revertir",
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Si, eliminar'
                }).then(result => {
                    if (result.value) {
                        cuestionario.get('preguntas').then(preguntas => {
                            preguntas.forEach(pregunta => {
                                pregunta.get('respuestas').then(respuestas => {
                                    respuestas.forEach(respuesta => {
                                        respuesta.destroyRecord();
                                    });
                                });
                                pregunta.destroyRecord();
                            });
                        });
                        cuestionario.destroyRecord();
                    }
                });
            },
            editar(cuestionario) {
                this.transitionToRoute('administrador.cuestionarios.editar', cuestionario);
            },
            ver(cuestionario) {
                this.transitionToRoute('administrador.cuestionarios.detalles', cuestionario);
            },
            aplicar(cuestionario) {
                this.transitionToRoute('administrador.cuestionarios.aplicar', cuestionario);
            }
        }
    });
});