define('cifunhi/components/kid-anexos', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    firebaseApp: Ember.inject.service(),

    anexo: null,

    saveFile(documento, nombreDocumento, documentoRef) {

      let pdf = window.$(documentoRef)[0].files[0];
      let model = this.get('model');
      let cntx = this;
      let storageRef = cntx.get('firebaseApp').storage().ref();

      documento.set('nombre', nombreDocumento);

      if (pdf.type.match('.pdf') || pdf.type.match('image/*')) {
        let fileType = pdf.type.split("/")[1];
        var reader = new FileReader();
        reader.readAsArrayBuffer(pdf);
        return reader.onload = function (e) {
          var data = e.target.result;
          var imgRef = storageRef.child(`documentos/${model.get('id')}/${nombreDocumento}.${fileType}`);
          imgRef.put(data).then(function (snapshot) {

            documento.set('archivo', snapshot.downloadURL);
            documento.set('nombre', `${nombreDocumento}.${fileType}`);
            return documento.save();
          });
        };
      }
    },
    actions: {
      editar(valor) {
        this.set(valor, true);
      },
      cancelarEdit(valor) {
        this.set(valor, false);
      },
      eliminar(anexo) {

        let kid = this.get('model');

        let cntx = this;
        let storageRef = cntx.get('firebaseApp').storage().ref();
        let docRef = storageRef.child(`documentos/${kid.id}/${anexo.nombre}`);

        window.swal({
          title: 'Eliminar',
          text: "¿Seguro de que desea borrar este documento anexo?",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          cancelButtonText: 'No',
          confirmButtonText: 'Si'
        }).then(result => {
          if (result.value) {
            return docRef.delete().then(() => {
              return anexo.destroyRecord().then(() => {
                Swal('Eliminado', 'El archivo se ha eliminado correctamente', 'success');
              });
            });
          }
        });
      },
      addAnexo() {
        this.set('newAnexo', true);
      },
      cancelarArchivo() {
        this.set('anexoNombre', "");
        this.set('anexoDescripcion', "");
        this.set('newAnexo', false);
      },
      saveArchivo(anexo) {
        let cntx = this;
        let anexoNuevo = this.get('store').createRecord('anexo', {
          nombre: this.get('anexoNombre'),
          descripcion: this.get('anexoDescripcion'),
          kid: this.get('model')
        });

        this.saveFile(anexoNuevo, anexoNuevo.nombre, "#archivo");

        anexoNuevo.save().then(anexoGuardado => {
          let model = cntx.get('model');

          model.get('anexos').then(anexosKid => {
            anexosKid.pushObject(anexoGuardado);
            model.save().then(() => {
              cntx.set('anexoNombre', "");
              cntx.set('anexoDescripcion', "");
              cntx.set('newAnexo', false);
            });
          });
        }).finally(() => {
          this.set('newAnexo', false);
        });
      }
    }
  });
});