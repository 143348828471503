define('cifunhi/routes/application', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        moment: Ember.inject.service(),
        beforeModel() {
            this.get('moment').setLocale('es');
            this.get('moment').setTimeZone('America/Mexico_City');
        }
    });
});