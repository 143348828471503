define('cifunhi/services/md-settings', ['exports', 'ember-cli-materialize/services/md-settings'], function (exports, _mdSettings) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _mdSettings.default;
    }
  });
});