define('cifunhi/models/servicio-medico', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		institucion: _emberData.default.attr('string'),
		hospitalizado: _emberData.default.attr('boolean'),
		tipoSangre: _emberData.default.attr('string'),
		cirugia: _emberData.default.attr('boolean'),
		alergia: _emberData.default.attr('string'),
		nino: _emberData.default.belongsTo('kid')
	});
});