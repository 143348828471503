define('cifunhi/controllers/login', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const Validations = (0, _emberCpValidations.buildValidations)({
    user: (0, _emberCpValidations.validator)('presence', true),
    pass: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('presence', true)]
  });

  exports.default = Ember.Controller.extend(Validations, {

    actions: {
      toogleError(attr) {
        this.validate().then(({ validations }) => {
          switch (attr) {
            case 'user':
              if (Ember.get(this, 'validations.attrs.user.isInvalid')) {
                this.set('loginUserError', 'Este campo no puede estar vacío.');
                this.send('activateError', '#loginuser');
              } else {
                this.set('loginUserError', null);
                this.send('desactivateError', '#loginuser');
              }
              break;
            case 'pass':
              if (Ember.get(this, 'validations.attrs.pass.isInvalid')) {
                if (Ember.get(this, 'validations.attrs.pass.error.type') == 'presence') this.set('loginPassError', 'Este campo no puede estar vacío.');else this.set('loginPassError', 'La contraseña es demasiado corta.');
                this.send('activateError', '#loginpass');
              } else {
                this.set('loginPassError', null);
                this.send('desactivateError', '#loginpass');
              }
              break;

          }
        });
      },
      signIn() {
        this.set('isWorking', true);
        this.validate().then(({ validations }) => {
          if (Ember.get(this, 'validations.isValid')) {
            let newemail = this.get("user");
            let pass = this.get("pass");
            this.get('session').open('firebase', {
              provider: 'password',
              email: newemail,
              password: pass
            }).then(() => {
              this.set('pass', undefined);
              this.set('isWorking', false);
              this.transitionToRoute('administrador');
            }).catch(error => {
              console.log(error);
              window.swal({
                type: 'error',
                title: 'Ooops...',
                text: 'Usuario y/o contraseña incorrectos.'
              });
              this.set('isWorking', false);
            });
          } else {
            swal({
              type: 'warning',
              title: 'Oops...',
              text: '¡No puedes dejar campos vacios!'
            });
          }
        }).catch(error => {});
      },
      activateError(id) {
        window.$(id).removeClass('valid');
        window.$(id).addClass('invalid');
      },
      desactivateError(id) {
        window.$(id).removeClass('invalid');
        window.$(id).addClass('valid');
      }
    }
  });
});